<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="500"
    @close="onClose"
    @ok="onSubmit((e) => onSubmitFun(e))"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form>
        <e-input
          title="用户名"
          :validate="validateInfos.uname"
          v-model:value="modelRef.uname"
          :disabled="isEdit"
        />

        <e-input
          title="密码"
          :validate="validateInfos.pwd"
          v-model:value="modelRef.pwd"
          v-if="!isEdit"
          type="password"
        />

        <e-input
          title="确认密码"
          :validate="validateInfos.password_confirm"
          v-model:value="modelRef.password_confirm"
          v-if="!isEdit"
          type="password"
        />

        <e-input
          title="姓名"
          :validate="validateInfos.name"
          v-model:value="modelRef.name"
        />

        <e-input title="手机号" v-model:value="modelRef.mobile" />

        <e-radio title="性别" v-model:value="modelRef.sex" :options="sexes" />

        <e-select
          title="角色"
          v-model:value="modelRef.roleId"
          :options="roles"
          :disabled="true"
        />
      </a-form>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref, reactive, toRaw } from 'vue'
import { Form } from 'ant-design-vue'
import form from '@/mixins/form'
import { UserClass } from '@/apis/user'

const useForm = Form.useForm
const api = new UserClass()
export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  props: {
    allowRoles: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)
    const isEdit = ref(false)
    const title = ref('')
    const roles = ref([])
    const sexes = ref([])

    const modelRef = reactive(api.modelRef)

    const validatePass = async (rule, value) => {
      if (value === '') {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('请确认密码')
      } else {
        if (value !== modelRef.pwd) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('两次密码不一致')
        }

        return Promise.resolve()
      }
    }

    const rulesRef = reactive({
      ...api.rulesRef,
      password_confirm: [
        {
          required: true,
          validator: validatePass
        }
      ]
    })

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    )

    const onClose = () => {
      resetFields()
      loading.value = false
      visible.value = false
    }

    return {
      title,
      visible,
      loading,
      isEdit,
      modelRef,
      validateInfos,
      resetFields,
      validate,
      onClose,
      roles,
      sexes
    }
  },
  methods: {
    open (formData) {
      if (formData) {
        this.modelRef.uname = formData.uname
        this.modelRef.mobile = formData.mobile
        this.modelRef.name = formData.name
        this.modelRef.sex = formData.sex
        this.modelRef.roleId = formData.roleIds[0]
        this.modelRef.pwd = '密码'
        this.modelRef.password_confirm = '密码'
        this.modelRef.id = formData.id
        this.title = '编辑：' + formData.name
        this.isEdit = true
      } else {
        this.modelRef = reactive(api.modelRef)
        this.modelRef.roleId = this.allowRoles[0]
        this.title = '添加用户'
        this.isEdit = false
      }

      this.sexes = this.$store.state.app.sexes

      this.getRoles()
      this.visible = true
    },

    async getRoles () {
      const list =
        this.$store.state.data.ALL_ROLES.length > 0
          ? this.$store.state.data.ALL_ROLES
          : await this.$store.dispatch('GetAllRoles').then()

      const vlist = []
      list.map((e) => {
        vlist.push({
          label: e.name,
          value: e.id
        })
      })

      this.roles = vlist
    },

    filterOption (input, option) {
      return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },

    onSubmitFun (e) {
      const data = toRaw(e)
      if (this.isEdit) {
        api
          .update(data)
          .then(() => {
            this.loading = false
            this.$message.success('编辑成功')
            this.$emit('ok', data)
            this.onClose()
          })
          .catch(() => {
            this.loading = false
          })
      } else {
        api
          .create(data)
          .then(() => {
            this.loading = false
            this.$message.success('添加成功')
            this.$emit('ok', data)
            this.onClose()
          })
          .catch(() => {
            this.loading = false
          })
      }
    }
  }
})
</script>
